import React from 'react';
import './Header.css';
import 'bulma/css/bulma.min.css';
import './Responsive.css';

const header = () => {
    return (
        <div className="header"></div>
    )
}

export default header;