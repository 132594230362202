import React from 'react';
import './Responsive.css';
import './Footer.css';
import 'bulma/css/bulma.min.css';



const Footer = () => {

    return (
        <>
            <section className="section myFooter">
                <div className="container">
                    <div className="is-flex is-align-items-flex-end is-justify-content-space-between is-flex-wrap-wrap">
                        <div className="left is-flex is-flex-direction-column">
                            <div className="mainLogo"></div>
                            <p className="is-size-6">
                                Medicated Mixe are a collection of 5000 unique algorithmically generated NFTs. The mice are stored as ERC-721 tokens on the Ethereum blockchain.
                            </p>
                        </div>
                        <div className="center is-flex is-flex-direction-column">
                            <div className="quickLinks"></div>
                            <div className="is-flex is-flex-wrap-wrap myLinks">
                                <div className="columns">
                                    <div className="column is-flex is-flex-direction-column">
                                        <a className="is-uppercase is-size-6" href="https://www.medicatedmice.co/">Home</a>
                                        <a className="is-uppercase is-size-6" href="https://www.medicatedmice.co/#about">About</a>
                                        <a className="is-uppercase is-size-6" href="https://www.medicatedmice.co/#roadmap">Roadmap</a>
                                    </div>
                                    <div className="column is-flex two is-flex-direction-column">
                                        <a className="is-uppercase is-size-6" href="https://www.medicatedmice.co/">How To Buy</a>
                                        <a className="is-uppercase is-size-6" href="https://www.medicatedmice.co/#about">Team</a>
                                        <a className="is-uppercase is-size-6" href="https://www.medicatedmice.co/#roadmap">FAQ</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right is-flex is-flex-direction-column">
                            <div className="follow"></div>
                            <div className="is-flex is-flex-wrap-wrap myLinks">
                                <div className="columns">
                                    <div className="column is-flex is-flex-direction-column">
                                        <div className="twitter">
                                            {/* <div className="image"></div> */}
                                            <a href="https://twitter.com/medicatedmice" className="is-size-6 is-uppercase">twitter</a>
                                        </div>
                                        <div className="instagram">
                                            {/* <div className="image"></div> */}
                                            <a href="https://www.instagram.com/medicatedmicenfts/" className="is-size-6 is-uppercase">instagram</a>
                                        </div>
                                        <div className="discord">
                                            {/* <div className="image"></div> */}
                                            <a href="https://discord.com/invite/cCM8CHm3" className="is-size-6 is-uppercase">discord</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;