import React, { useEffect, useState, useRef } from "react";
import Countdown from "react-countdown";
import "./Mint.css";
import "bulma/css/bulma.min.css";
import "./Responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

function Mint() {
  const saleActive = true;
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    saleActive
      ? "Click to mint a Baby Medicated Mice NFT!"
      : "The Baby Medicated Mice Mint is Currently Not Live"
  );
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const decreaseMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const increaseMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1420) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmount);
    let res = await fetch(
      "https://8loxl6oix0.execute-api.us-east-1.amazonaws.com/proof/" +
        blockchain.account,
      {
        referrerPolicy: "strict-origin-when-cross-origin",
        body: null,
        method: "GET",
        mode: "cors",
        credentials: "omit",
      }
    );

    let proof = await res.json();


    let balance = await blockchain.web3.eth.getBalance(blockchain.account, (err, balance) => { console.log(blockchain.account + " Balance: ", blockchain.web3.utils.fromWei(balance)) });

    if (balance <= cost * mintAmount) {
      setFeedback(`You do not have funds to cover this transaction!`);
      return;
    }


    const tx = blockchain.smartContract.methods.mint(mintAmount, proof);
    const [gasPrice, gasCost] = await Promise.all([
      blockchain.web3.eth.getGasPrice(),
      tx.estimateGas({ from: blockchain.account, value: totalCostWei, data: blockchain.smartContract.methods.mint(mintAmount, proof).encodeABI(),}),
    ]);

    console.log("Gas: ", gasCost);
    console.log("Cost: ", totalCostWei);
    console.log("Account: ", blockchain.account);
    console.log("Proof: ", proof);
    setFeedback(`Minting your Baby Medicated Mice, Please check your MetaMask!`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount, proof)
      .send({
        gas: gasCost,
        to: CONFIG.CONTRACT_ADDRESS,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        alert(err);
        setFeedback("Oops, We have ran into an issue please try again!");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have successfully purchased a Baby Medicated Mice check your OpenSea!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  function ButtonIncrement(props) {
    return (
      <button
        disabled={saleActive === false}
        className="myButton is-size-2"
        onClick={props.onClickFunc}
      >
        +
      </button>
    );
  }
  function ButtonDecrement(props) {
    return (
      <button
        disabled={saleActive === false}
        className="myButton is-size-2"
        onClick={props.onClickFunc}
      >
        -
      </button>
    );
  }

  function ButtonConnect(props) {
    return (
      <button
        disabled={saleActive === false}
        className="button purple-outline tatty2 has-text-weight-bold mt-5"
        onClick={props.onClickFunc}
      >
        {saleActive
          ? "Connect MetaMask Wallet!"
          : "The Mint is Currently Not Live!"}
      </button>
    );
  }

  function ButtonBuy(props) {
    return (
      <button
        disabled={
          claimingNft ? 1 : 0 || Number(data.totalSupply) >= 1420
        }
        className="button purple-outline tatty2 has-text-weight-bold mt-5"
        onClick={props.onClickFunc}
      >
        {claimingNft ? "MINTING" : "MINT"}
      </button>
    );
  }

  function TextDescription(props) {
    return (
      <button
        className="button purple-outline has-text-weight-bold is-size-6 tattyEdit"
        message={props}
      ></button>
    );
  }

  function Display(props) {
    return (
      <p className="is-size-4 has-text-weight-bold" id="num">
        {props.message}
      </p>
    );
  }

  function DisplayError(props) {
    return (
      <p className="is-size-6 has-text-weight-semibold" id="num">
        {props.message}
      </p>
    );
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <p>done</p>;
    } else {
      return (
        <>
          <div className="mydate"></div>
          <span className="mr-5">{days} Days</span>
          <div className="mydate"></div>
          <span className="mr-5">{hours} Hours</span>
          <div className="mydate"></div>
          <span className="mr-5">{minutes} Minutes</span>
          <div className="mydate"></div>
          <span className="mr-5">{seconds} Seconds</span>
        </>
      );
    }
  };

  return (
    <>
      <div className="container">
        <div className="is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-space-between wrap newCenter">
          <div className="left">
            <div className="myBox is-flex is-flex-direction-column is-align-items-center is-justify-content-space-evenly has-text-white">
              <p className="is-size-4 has-text-weight-bold">Price: 0.05 ETH</p>
              <p className="is-size-13 has-text-weight-semibold">
                Excluding gas fees.
              </p>
              <p className="is-size-6 has-text-weight-semibold">
                {data.totalSupply} / 1420
              </p>
              <p className="is-size-6 has-text-weight-semibold">{feedback}</p>
              <div className="mintAmount is-flex is-justify-content-space-evenly is-align-items-center has-text-centered">
                <ButtonDecrement
                  disabled={claimingNft ? 1 : 0}
                  onClickFunc={(e) => {
                    e.preventDefault();
                    decreaseMintAmount();
                  }}
                />
                <Display
                  message={
                    Number(data.totalSupply) >= 1420
                      ? "SOLD OUT"
                      : mintAmount
                  }
                />
                <ButtonIncrement
                  disabled={claimingNft ? 1 : 0}
                  onClickFunc={(e) => {
                    e.preventDefault();
                    increaseMintAmount();
                  }}
                />
              </div>
              {blockchain.errorMsg !== "" ? (
                <>
                  <DisplayError message={blockchain.errorMsg}></DisplayError>
                </>
              ) : null}
              {blockchain.account == "" || blockchain.smartContract == null ? (
                <ButtonConnect
                  disabled={saleActive === false}
                  onClickFunc={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                    console.log(getData());
                  }}
                />
              ) : (
                <ButtonBuy
                  disabled={claimingNft ? 1 : 0}
                  onClickFunc={(e) => {
                    e.preventDefault();
                    claimNFTs();
                    getData();
                  }}
                ></ButtonBuy>
              )}
            </div>
          </div>
          <div className="right">
            <div className="mint text-area is-flex is-flex-direction-column is-align-items-flex-start">
              <p className="is-size-3 has-text-weight-bold has-text-white">
                Mint Your <span className="purple">NFT</span>
              </p>
              <p className="is-size-6 has-text-white mt-4 myStyle3">
                It's time to mint your Baby Medicated Mice NFT! Join our discord for
                information regarding wallet restrictions and holder benefits!
              </p>
              <a
                href="https://discord.gg/ExmtXsGmwR"
                className="button purple-nooutline has-text-weight-semibold mt-5"
              >
                Join Our Discord
              </a>
            </div>
            <p className="is-size-5 is-uppercase has-text-weight-bold mt-6">
              Countdown TO Launch
            </p>
            <div className="timer is-flex is-align-items-flex-end myCenter">
              <Countdown
                date={new Date("2022-05-14T13:00:00")}
                renderer={renderer}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mint;
