import React from 'react';
import './font.css';
import 'bulma/css/bulma.min.css';
import './Responsive.css';
import './Showcase.css';

const Showcase = () => {
    return (
        <section className="section mission">
            <div className="container">
                <div className="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap mobileCenter newOne">
                    <div className="left">
                        <div className="text-area is-flex is-flex-direction-column is-align-items-flex-start mainClass">
                            <p className="is-size-3 has-text-weight-bold has-text-white mb-4">Our <span className="purple">Mission</span></p>
                            <p className="is-size-6 has-text-white mt-4 mainText">
                                Imagine this scenario: The year is 2023. You equip your VR 
                                headset, and take a trip into the Metaverse.
                            </p>
                            <p className="is-size-6 has-text-white mt-4 mainText">
                                You take a walk down the street to a local marijuana dispensary. 
                                Welcome to Candy Land! You purchase some cannabis and 
                                receive the product at your door-step in the real world.
                            </p>
                            <p className="is-size-6 has-text-white mt-4 mainText">
                                This fun and exciting shopping experience in the digital world 
                                will ultimately translate into a physical product at your doorstep 
                                in the real world. Pretty cool right?
                            </p>
                            <a href="https://discord.gg/ExmtXsGmwR" className="button purple-nooutline has-text-weight-semibold mt-6">Join Our Discord</a>
                        </div>
                    </div>
                    <div className="right is-flex is-flex-wrap-wrap images">
                        <div className='mr-5 mb-5 mouse1'></div>
                        <div className='mb-5 mouse2'></div>
                        <div className='mr-5 mb-5 mouse3'></div>
                        <div className='mb-5 mouse4'></div>
                        {/* <img src="./assets/1.jpg" alt="mouse" className="mr-5 mb-5 mouse1"/>
                        <img src="./assets/2.jpg" alt="mouse" className="mb-5 mouse2"/>
                        <img src="./assets/3.jpg" alt="mouse" className="mr-5 mb-5 mouse3"/>
                        <img src="./assets/4.jpg" alt="mouse" className="mb-5 mouse4"/> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Showcase;