import React from 'react';
import ReactDOM from 'react-dom';
import Header from './Header.js';
import Nav from './Nav.js';
import Mint from './Mint.js';
import Showcase from './Showcase';
import Footer from './Footer';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store";

ReactDOM.render(
  <>
    <Nav />
    <Header />
    <Provider store={store}> <Mint /> </Provider>
    <Showcase />
    <Footer />
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
