import React, {useState } from 'react';
import './Nav.css';
import './font.css';
import 'bulma/css/bulma.min.css';
import './Responsive.css';

const Nav = () => {

    const [ menuOpen, setMenuOpen ] = useState(false);
    const handleClick = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <div className="container">
        <nav className="navbar is-transparent py-4 px-5" role="navigation">
            <div className="navbar-brand">
                
                <a href="index.html" className="navbar-item logo yellow">
                    <div className="navLogo"></div>
                    <p className="is-size-4 ml-2 has-text-white myStyle" >Medicated Mice</p>
                </a>
            
                <button type="button" className={`navbar-burger ${menuOpen ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={handleClick}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>
        
            <div id="navbarBasicExample" className={`navbar-menu ${menuOpen ? 'is-active' : ''}`}>
                <div className="navbar-end">
                    <a className="navbar-item link is-uppercase active" href="https://www.medicatedmice.co/#about">About</a>
                    <a className="navbar-item link is-uppercase active" href="https://www.medicatedmice.co/#buy">How To Buy</a>
                    <a className="navbar-item link is-uppercase active" href="https://www.medicatedmice.co/#roadmap">Roadmap</a>
                    <a className="navbar-item link is-uppercase active" href="https://www.medicatedmice.co/#team">Team</a>
                    <a className="navbar-item link is-uppercase active" href="https://www.medicatedmice.co/#faq">FAQ</a>

                    <div className="icons is-flex is-align-items-center ml-5">
                        <a href="https://twitter.com/medicatedmice" className="mr-2 icon">
                            <div className="twitter icon"></div>
                            {/* <img src="./assets/twitter.svg" alt="twitter"/> */}
                        </a>
                        <a href="https://www.instagram.com/medicatedmicenfts/" className="mr-2 icon">
                            <div className="instagram icon"></div>
                            {/* <img src="./assets/instagram.svg" alt="instagram"/> */}
                        </a>
                        <a href="https://discord.gg/cCM8CHm3" className="icon">
                            <div className="discord icon"></div>
                            {/* <img src="./assets/discord.svg" alt="discord"/> */}
                        </a>
                    </div>
                    <a href="https://opensea.io/collection/medicated-mice" className="is-uppercase has-text-weight-semibold is-align-self-center mx-5 purple myStyle2">View On Opensea</a>
                </div>
            </div>
        </nav>
    </div>
    )
}

export default Nav;